<template>
  <v-sheet
    color="#379C6F"
    class="d-flex align-center justify-center"
    height="100%"
  >
    <v-img
      v-if="!$vuetify.breakpoint.mobile"
      class="d-flex align-center justify-center fill-height"
      src="@/assets/landing-page/computer-eConsenso.png"
    >
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" sm="6" md="6" lg="6" offset="6">
            <v-card class="pa-4">
              <v-col cols="12">
                <div class="primary--text text-start">
                  <div class="title1" style="line-height: 1">
                    Mantenha-se por dentro da LGPD, receba em seu e-mail:
                    Informações, Novidades, Dicas e Muito Mais!
                  </div>
                  <div class="mt-4">
                    Você receberá no seu e-mail tudo o que precisa saber sobre
                    a Lei Geral de Proteção de Dados, para manter-se
                    atualizado ou mesmo poder seguir todas as diretrizes de
                    forma segura e sem complicações.
                  </div>
                </div>
                <v-form class="mt-4" ref="leadForm">
                  <v-text-field
                    label="Nome *"
                    placeholder="Nome *"
                    v-model="lead.name"
                    required
                    hide-details="auto"
                    solo
                    dense
                    class="mt-2"
                    :rules="[$rules.required]"
                    :disabled="loading"
                  />
                  <v-text-field
                    label="Telefone *"
                    v-model="lead.phone"
                    required
                    hide-details="auto"
                    solo
                    dense
                    class="mt-2"
                    :rules="[$rules.required, $rules.phone]"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    placeholder="(00) 00000-0000"
                    :disabled="loading"
                  />
                  <v-text-field
                    label="E-Mail *"
                    placeholder="E-Mail *"
                    v-model="lead.email"
                    required
                    hide-details="auto"
                    solo
                    dense
                    class="mt-2"
                    :rules="[$rules.required, $rules.email]"
                    :disabled="loading"
                  />
                  <v-checkbox
                    label="Concordo em receber comunicações sobre a LGPD e o sistema eConsenso"
                    :disabled="loading"
                    :rules="[(v) => !!v || 'Você deve concordar para continuar']"
                  />
                  <v-btn
                    color="primary"
                    class="mt-2"
                    block
                    @click="sendForm()"
                    :loading="loading"
                  >
                    RECEBER INFORMATIVOS SOBRE A LGPD
                  </v-btn>
                </v-form>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-img>

    <v-container v-else my-10>
      <div class="d-flex flex-column justify-space-between align-center">
        <v-img src="@/assets/landing-page/computer.png" contain />
      </div>
      <div class="white--text text-center">
        <div class="title1" style="line-height: 1">
          Mantenha-se por dentro da LGPD, recebendo periodicamente no seu
          e-mail: Informações, Novidades, Dicas e Muito mais!
        </div>
        <div class="mt-4">
          Você receberá no seu e-mail tudo o que precisa saber sobre a Lei Geral
          de Proteção de Dados, para manter-se atualizado ou mesmo poder seguir
          todas as diretrizes de forma segura e sem complicações.
        </div>
      </div>
      <v-form class="mt-4" ref="leadForm">
        <v-text-field
          label="Nome *"
          placeholder="Nome *"
          v-model="lead.name"
          required
          hide-details="auto"
          solo
          dense
          class="mt-2"
          :rules="[$rules.required]"
          :disabled="loading"
        />
        <v-text-field
          label="Telefone *"
          v-model="lead.phone"
          required
          hide-details="auto"
          solo
          dense
          class="mt-2"
          :rules="[$rules.required, $rules.phone]"
          v-mask="['(##) ####-####', '(##) #####-####']"
          placeholder="(00) 00000-0000"
          :disabled="loading"
        />
        <v-text-field
          label="E-Mail *"
          placeholder="E-Mail *"
          v-model="lead.email"
          required
          hide-details="auto"
          solo
          dense
          class="mt-2"
          :rules="[$rules.required, $rules.email]"
          :disabled="loading"
        />
        <v-checkbox
          label="Concordo em receber comunicações sobre a LGPD e o sistema eConsenso"
          :disabled="loading"
          :rules="[(v) => !!v || 'Você deve concordar para continuar']"
        />
        <v-btn color="primary" class="mt-2" block :loading="loading" @click="sendForm()">
          RECEBER INFORMATIVOS
        </v-btn>
      </v-form>
    </v-container>

    <Footer />
  </v-sheet>
</template>

<script>
import Footer from "@/layout/default/Footer.vue";

export default {
  name: "LandingNewsletter",
  components: {
    Footer,
  },
  data: () => ({
    loading: false,
    lead: {
      name: "",
      email: "",
      phone: "",
    },
  }),
  methods: {
    sendForm() {
      if (this.$refs.leadForm.validate()) {
        this.loading = true;
        const queryParams = Object.entries(this.$route.query).map(([key, val]) => `${key}=${val}`)
        this.$axios
          .post("/subscribe-to-newsletter", {
            ...this.lead,
            tags: ["LP Newsletter", ...queryParams],
          })
          .then(() => {
            this.$showSuccessMessage(
              "Agradecemos pelo seu Contato e Interesse.",
              "Enviaremos ao seu e-mail, periodicamente: Atualidades, Dicas, Informações e muito mais sobre a LGPD. Fique atento! Faremos o nosso melhor para contribuir com seu Empreendimento, frente aos desafios da LGPD."
            );
            this.$refs.leadForm.reset()
          })
          .catch(() => {
            this.$showMessage(
              "error",
              "Ocorreu um erro inesperado. Por favor, contate nosso suporte em contato@econsenso.com.br"
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$showMessage(
          "warning",
          "Por favor, preencha os campos obrigatórios"
        );
      }
    },
  },
};
</script>

<style scoped>
.break {
  word-break: break-word;
}
.title1 {
  font-size: 32px;
  font-weight: bold;
}
</style>
